.container,
.container-fluid {
  > .navbar-header,
  > .navbar-collapse {
    margin-right: 10px !important;
    margin-left: 0 !important;
   
  }
}
.navbar-inverse .navbar-nav > li > a:hover {
  background-color:#66869c;
}
.buton{
  color: white !important;
  
}


.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index:99;
    
  }
  .colour{
    color:black;
  }
  li{
    color:black !important;
  }
  img{
    height: 30px;
  }
  .name{
    margin-top: 15px;
    text-decoration-color:black;
    font-size: 15px;
  }
  .dd{
    background-color: #0db0bf;
    
  }
  .txt{
    text-decoration-color:black;
  }
  
  .dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 90%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }
    
    .dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
    }
    
    .dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
    }
    .navbar-custom {
      background-color:white;
  }
      .navbar-custom .navbar-brand,
      .navbar-custom .navbar-text {
        color:black;
    }
    .navbar-hover:hover>.navbar{
      text-decoration-color: black;
    }
    .navbar-inverse .navbar-toggle:active{
      color:white;
      text-decoration-color: black;
    }
  